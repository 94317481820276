@tailwind base;
@tailwind components;
@tailwind utilities;


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@layer components {
  /* .input {
    @apply p-2 px-3 bg-gray-50 border-2 rounded-sm  my-2 w-full focus:bg-white focus:outline-none  focus:border-blue-300;
  } */
  /* body {
    font-family: 'Quicksand', sans-serif;

  } */
  .input {
    @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
  }

  .input-label {
      @apply inline-block mb-2 text-gray-700;
  }
  .input-control {
      @apply  block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
  }
  .input-default {
      @apply border border-gray-300 px-2 py-1 w-full
  }
  .autocomplete{
      @apply bg-white border-2 border-blue-300 p-0 text-sm font-mono z-10;
  }
  
  .table {
      @apply text-sm w-full shadow my-4 ;
  }
 
  .table thead {
      @apply border border-gray-500  rounded-sm ;
  }
  .table tbody {
      @apply border border-gray-300 ;
  }
  .table thead tr  {
      @apply px-4 py-2 bg-gray-500 text-white ;
  }
  .table thead tr th {
      @apply px-2 py-2  text-left text-xs font-medium  uppercase tracking-wider;
  }
  /* .table tbody tr:nth-child(even){
      @apply bg-gray-100;
  } */
  .table tbody tr:hover {
      @apply bg-green-200 cursor-pointer;
  }
  .table tbody tr{
    @apply border-b border-white ;
}
  .table tbody tr td {
      @apply px-2 py-1 ;
  }

  .main-content {
      @apply p-3 pt-5 bg-gray-200;
  }
  .tab-container {
      @apply text-lg font-sans  ;
  }
  .tab {
      @apply px-4 py-2  rounded-t-lg ;
  }
  .btn-danger{
      @apply w-full inline-flex  cursor-pointer justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none disabled:opacity-50  sm:ml-3 sm:w-auto sm:text-sm;
  }
  .btn-success{
      @apply w-full inline-flex  cursor-pointer justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm;
  }
  .btn-default{
      @apply w-full inline-flex cursor-pointer justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  disabled:opacity-50  sm:ml-3 sm:w-auto sm:text-sm;
  }
  .btn-warning{
      @apply mt-3 w-full inline-flex cursor-pointer justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-yellow-400 text-base font-medium text-gray-700 hover:bg-yellow-500 focus:outline-none  disabled:opacity-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
  }
  .btn-primary{
      @apply mt-3 w-full inline-flex cursor-pointer justify-center rounded-md border border-blue-400 shadow-sm px-4 py-2 bg-blue-400 text-base font-medium text-white hover:bg-blue-500 focus:outline-none  disabled:opacity-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
  }
  .alert-success {
      @apply bg-green-100 border-t-4 border-green-400 rounded-b text-green-600  px-4 py-3 shadow-md my-2;
  }
  .alert-danger {
    @apply bg-red-100 border-t-4 border-red-400 rounded-b text-red-600  px-4 py-3 shadow-md my-2;
}



  
}
.autocomplete div{
  padding: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.autocomplete div.selected {
    background-color: #f5f5f5;
}
.popover:after {  
  position: absolute;
  bottom: 0;
  height: 0;
  width: 0;
  top: 40%;
  left: -19px;
  border: 10px solid transparent;
  border-right-color: #9ca3af;
  content: "";
}

